var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticClass:"mt-2"},[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CCol',{attrs:{"sm":"12","lg":"12","xl":"12"}},[_c('CRow',[_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                    content: _vm.$t('label.eirTransaction'),
                    placement: 'top-end'
                    }),expression:"{\n                    content: $t('label.eirTransaction'),\n                    placement: 'top-end'\n                    }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleTranscCreate}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v("  "+_vm._s(_vm.$t('label.nueva'))+" ")],1)],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedListEir,"fields":_vm.transcfields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                              content: _vm.$t('label.edit')+' '+_vm.$t('label.eirTransaction'),
                              placement: 'top-end'
                            }),expression:"{\n                              content: $t('label.edit')+' '+$t('label.eirTransaction'),\n                              placement: 'top-end'\n                            }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.toggleEirTranscEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])})],1)],1)],1),_c('ModalCreateEirTransc',{attrs:{"modal":_vm.modalTranscCreate},on:{"update:modal":function($event){_vm.modalTranscCreate=$event},"set-modal-eir-transc-list":_vm.setEirList}}),_c('ModalEditEirTransc',{attrs:{"modal":_vm.modalEirTranscUpdate,"EirTranscSelect":_vm.EirTranscSelect},on:{"update:modal":function($event){_vm.modalEirTranscUpdate=$event},"set-modal-edit-eir-transc-list":_vm.setEirList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }